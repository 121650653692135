
import {
    Box, Text, Badge, Flex
} from "@chakra-ui/react"
import AudiogramChart from './AudiogramChart';
import { useSelector } from 'react-redux'
import { AudiometryConclusionUnit, Side } from './AudiometryModel';

export default function AudiogramComponent(params: { side: Side }) {

    const conclusions = useSelector((state: any) => state.player.conclusions)

    const data = conclusions[params.side].map((conclusion: AudiometryConclusionUnit) => ({ hz: Number(conclusion.hz), dB: conclusion.decibel }))
        .sort((a: any, b: any) => Number(a.hz) < Number(b.hz) ? 1 : -1)

    const props = params.side === Side.Left ? { left: data } : { right: data }

    const ptaCandidates = conclusions[params.side]
        .filter((conclusion: AudiometryConclusionUnit) => (conclusion.hz === 500 || conclusion.hz === 1000 || conclusion.hz === 2000 || conclusion.hz === 4000))
        .map((conclusion: AudiometryConclusionUnit) => conclusion.decibel)

    const pta = ptaCandidates.length < 4 ? null
        : ptaCandidates.reduce((a: number, b: number) => a + b, 0) / ptaCandidates.length;

    return (
        <Box boxShadow="base" rounded="md" bg="white" width="400px" height="400px">
            <Flex justifyContent="space-between" p="4" pb="2">
                <Text
                    fontSize="xs"
                    textTransform="uppercase"
                >Audiogram: {params.side}</Text>
                <Flex alignItems="center">
    
                            <Text>PTA</Text>
                            <Badge ml="3" fontSize="1em">&nbsp;{pta ? pta : ``}&nbsp;</Badge>
       
                </Flex>
            </Flex>
            <AudiogramChart width={360} {...props} />
        </Box>
    )

}