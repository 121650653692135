import { configureStore } from '@reduxjs/toolkit'
import playerReducer from './player/playerSlice';
import calibrationReducer from './calibrator/calibrationSlice';
import appReducer from './appSlice';

export default configureStore({
  reducer: {
    player: playerReducer,
    calibration: calibrationReducer,
    app: appReducer,

  },
})