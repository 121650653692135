import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  setPip,
  setDecibelLabel,
  setHzLabel,
  setSideLabel,
  setReaction,
  unshiftPipEvent,
  setPlay,
  pushUnit,
  pushConclusion,
  flushPlayer,
  setPlayState,
} from './playerSlice'
import { AudiometryConclusionUnit, AudiometryModel, AudiometryUnit, DecibelTarget, PipEvent } from '../AudiometryModel';
import { useTimer } from "react-use-precision-timer";
import { CalibrationModel } from '../CalibrationModel';

export default function PlayerLogic(params: { playPip: Function, stopPip: Function }) {

  const dispatch = useDispatch()
  const reaction = useSelector((state: any) => state.player.reaction)
  const schema = useSelector((state: any) => state.calibration.schema)
  const play = useSelector((state: any) => state.player.play)

  const { playPip, stopPip } = params

  const onPipStart = (pipEvent: PipEvent) => {
    if (audiometry.hz === null) {
      return
    }

    const volume = CalibrationModel.getOutputVolume(audiometry.decibel, schema[audiometry.hz][audiometry.side])
    playPip(audiometry.hz, volume, audiometry.side, false)
    dispatch(setDecibelLabel(audiometry.decibel))
    dispatch(setHzLabel(audiometry.hz))
    dispatch(setSideLabel(audiometry.side))
    dispatch(setPip(true))
  }

  const onPipEnd = () => {
    stopPip()
    dispatch(setDecibelLabel(''))
    dispatch(setPip(false))
  }

  const onLog = (pipEvent: PipEvent) => {
    dispatch(unshiftPipEvent(pipEvent))
  }

  const onUnit = (unit: AudiometryUnit) => {
    dispatch(pushUnit(unit))
  }

  const onConclusion = (decibel: DecibelTarget, unit: AudiometryConclusionUnit) => {
    dispatch(pushConclusion(unit))
  }

  const onFinished = () => {
    dispatch(setPlay('shouldPause'))
  }

  const [audiometry] = React.useState(new AudiometryModel({
    onPipStart,
    onPipEnd,
    onLog,
    onUnit,
    onConclusion,
    onFinished,
  }))

  const timer = useTimer({
    delay: 50,
    callback: () => audiometry.tick(timer.getElapsedRunningTime())
  });

  useEffect(() => {

    // As loading this component creates a new AudiometryModel, also flush redux data.
    dispatch(flushPlayer(''))


  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const handleReaction = () => {
      audiometry.handleReaction()
    }

    if (reaction) {
      handleReaction()
      dispatch(setReaction(false))
    }
  }, [reaction, dispatch, audiometry])

  useEffect(() => {
    if (play === 'shouldPlay' && !audiometry.finished) {
      timer.isStarted() ? timer.resume() : timer.start()
      dispatch(setPlay(''))
      dispatch(setPlayState('play'))
    }
    else if (play === 'shouldPause') {
      timer.pause()
      dispatch(setPlay(''))
      dispatch(setPlayState('pause'))
    }
  }, [play, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps


  return null

}