import React from 'react'
import { Box, Text, Icon } from "@chakra-ui/react"
import * as d3 from 'd3';
import { MdClose as Cross, MdPanoramaFishEye as Circle } from "react-icons/md"

const consts = {
    dbAxisWidth: 60,
    dbAxisTickWidth: 6,
    hzAxisHeight: 40,
    hzAxisTextWidth: 60,
    dbLevels: [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
    hzLevels: [125, 250, 500, 1000, 2000, 3000, 4000, 6000, 8000, 16000],
    hzLevelsIndicate: [125, 250, 500, 1000, 2000, 4000, 8000, 16000],
    shapeSize: 16,
    blueColor: "blue",
    redColor: "red",
}



export default function AudiogramChart(props) {
    const { width, right=[], left=[] } = props

    const chartWidth = width - consts.dbAxisWidth

    const xScale = d3.scaleLog()
        .domain([consts.hzLevels[0], consts.hzLevels[consts.hzLevels.length - 1]])
        .range([0, chartWidth])


    // Audiogram requirement that 20 dB increase corresponds to hz doubling
    const height10dB = xScale(consts.hzLevels[1]) / 2
    const dbLevelsRange = Math.abs(consts.dbLevels[0] - consts.dbLevels[consts.dbLevels.length - 1])
    const chartHeight = (dbLevelsRange / 10) * height10dB
    const yScale = d3.scaleLinear()
        .domain([consts.dbLevels[0], consts.dbLevels[consts.dbLevels.length - 1]])
        .range([0, chartHeight])


    const makePath = (points) => {
        let path = ""

        for (let i = 0; i < points.length; i++) {
            const { dB, hz } = points[i]

            if (i === 0) {
                path = "M" // move to
            }
            else {
                path += "L" // line (to x, y)
            }

            path += xScale(hz) + " " + yScale(dB)
        }
        return path
    }

    return <Box name="wrapper"
        style={{ position: "relative" }}
        width={width}
        height={chartHeight + consts.hzAxisHeight}>

        <Box name="dbAxis"
            // bg="khaki"
            style={{ position: "absolute", top: consts.hzAxisHeight }}
            width={`${consts.dbAxisWidth}px`}
            height={`${chartHeight}px`}>

            <svg height="100%" width="100%"
                style={{
                    position: "absolute",
                    overflow: "visible"
                }}>

                <line y1={0} y2={chartHeight} x1={consts.dbAxisWidth - 1} x2={consts.dbAxisWidth - 1} stroke="currentColor" />

                {consts.dbLevels.map((dbLevel, i) => {
                    const y = yScale(dbLevel)
                    return <line
                        key={i}
                        y1={y}
                        y2={y}
                        x1={consts.dbAxisWidth - consts.dbAxisTickWidth}
                        x2={consts.dbAxisWidth}
                        stroke="currentColor"
                    />
                })}
            </svg>

            {consts.dbLevels.map((dbLevel, i) => {
                const y = yScale(dbLevel)
                return <Text
                    key={i}
                    w={consts.dbAxisWidth - consts.dbAxisTickWidth}
                    pr="1"
                    color="gray.500"
                    style={{
                        position: "absolute",
                        top: y,
                        transform: "translate(0, -50%)",
                        textAlign: "right",
                        overflow: 'hidden',
                        textOverflow: 'clip',
                        whiteSpace: 'nowrap',
                    }}
                    fontSize="sm"
                >{dbLevel}</Text>
            })}
        </Box>

        <Box name="hzAxis"
            //bg="aliceblue"
            style={{ position: "absolute", left: consts.dbAxisWidth }}
            width={`${chartWidth}px`}
            height={`${consts.hzAxisHeight}px`}>

            <svg height="100%" width="100%"
                style={{
                    position: "absolute",
                    overflow: "visible"
                }}>

                <line y1={consts.hzAxisHeight - 1} y2={consts.hzAxisHeight - 1} x1={0} x2={chartWidth} stroke="currentColor" />

                {consts.hzLevelsIndicate.map((hzLevel, i) => {
                    const x = xScale(hzLevel)
                    return <line
                        key={i}
                        y1={consts.hzAxisHeight - consts.dbAxisTickWidth}
                        y2={consts.hzAxisHeight}
                        x1={x}
                        x2={x}
                        stroke="currentColor"
                    />
                })}
            </svg>

            {consts.hzLevelsIndicate.map((hzLevel, i) => {
                const x = xScale(hzLevel)
                return <Text
                    key={i}
                    w={`${consts.hzAxisTextWidth}px`}
                    color="gray.500"
                    // pr="1"
                    style={{
                        position: "absolute",
                        top: consts.hzAxisHeight - consts.dbAxisTickWidth,
                        left: x,
                        transform: "translate(-25%, -160%) rotate(-35deg)",
                        textAlign: "left",
                        overflow: 'hidden',
                        textOverflow: 'clip',
                        whiteSpace: 'nowrap',
                    }}
                    fontSize="xs"
                >{hzLevel}</Text>
            })}

        </Box>

        <Box name="guideFields"
            style={{ position: "absolute", left: consts.dbAxisWidth, top: consts.hzAxisHeight }}
            width={`${chartWidth}px`}
            height={`${chartHeight}px`}>


            <svg height="100%" width="100%" style={{ position: "absolute", opacity: 0.8 }}>
                <rect
                    x={0}
                    y={0}
                    width={chartWidth}
                    height={yScale(90)}
                    style={{ fill: "rgb(249, 216, 213)" }} />
                <rect
                    x={0}
                    y={0}
                    width={chartWidth}
                    height={yScale(70)}
                    style={{ fill: "rgb(253, 237, 216)" }} />
                <rect
                    x={0}
                    y={0}
                    width={chartWidth}
                    height={yScale(55)}
                    style={{ fill: "rgb(255, 253, 218)" }} />
                <rect
                    x={0}
                    y={0}
                    width={chartWidth}
                    height={yScale(40)}
                    style={{ fill: "rgb(213, 215, 252)" }} />
                <rect
                    x={0}
                    y={0}
                    width={chartWidth}
                    height={yScale(25)}
                    style={{ fill: "rgb(216, 234, 213)" }} />
            </svg>

        </Box>

        <Box name="grid"
            // bg="gold"
            style={{ position: "absolute", left: consts.dbAxisWidth, top: consts.hzAxisHeight }}
            width={`${chartWidth}px`}
            height={`${chartHeight}px`}>

            <svg height="100%" width="100%"
                style={{
                    position: "absolute",
                    overflow: "visible"
                }}>

                {consts.hzLevels.map((hzLevel, i) => {
                    const x = xScale(hzLevel)
                    return <line
                        key={i}
                        y1={0}
                        y2={chartHeight}
                        x1={x}
                        x2={x}
                        stroke="#ccc"
                        strokeWidth="1"
                    />
                })}

                {consts.dbLevels.map((dbLevel, i) => {
                    const y = yScale(dbLevel)
                    return <line
                        key={i}
                        y1={y}
                        y2={y}
                        x1={0}
                        x2={chartWidth}
                        stroke="#ccc"
                        strokeWidth="1"
                    />
                })}
            </svg>

        </Box>

        <Box name="patterns"
            style={{ position: "absolute", left: consts.dbAxisWidth, top: consts.hzAxisHeight }}
            width={`${chartWidth}px`}
            height={`${chartHeight}px`}>
            <svg height="100%" width="100%"
                style={{
                    position: "absolute",
                    overflow: "visible"
                }}>


                <path d={makePath(left)}
                    stroke={consts.blueColor}
                    fill="transparent"
                    strokeWidth="1.5" />

                <path d={makePath(right)}
                    stroke={consts.redColor}
                    fill="transparent"
                    strokeWidth="1.5" />
                    
            </svg>

            {left.map(({ hz, dB }, i) => {
                return <Icon key={i}
                    as={Cross}
                    style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                    }}
                    h={`${consts.shapeSize}px`} w={`${consts.shapeSize}px`}
                    top={yScale(dB)}
                    left={xScale(hz)}
                    fill={consts.blueColor}

                />
            })}

            {right.map(({ hz, dB }, i) => {
                return <Icon key={i}
                    as={Circle}
                    style={{
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                    }}
                    h={`${consts.shapeSize}px`} w={`${consts.shapeSize}px`}
                    top={yScale(dB)}
                    left={xScale(hz)}
                    fill={consts.redColor}

                />
            })}

        </Box>

    </Box>
}