

export type HzLevel = null | 125 | 250 | 500 | 1000 | 2000 | 4000 | 6000 | 8000
export const HzLevels: HzLevel[] = [125, 250, 500, 1000, 2000, 4000, 6000, 8000]
export type DecibelTarget = -10 | -5 | 0 | 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60 | 65 | 70 | 75 | 80 | 85 | 90 | 95 | 100 | 105 | 110 | 115 | 120
export const DecibelLevels: DecibelTarget[] = [-10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120]
export const DecibelTestLevels: DecibelTarget[] = [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]

export type Decibel = number

export type AudiometryUnitLine = [ number, Decibel ]

export type AudiometryUnit = {
  unit: number,
  pretime: number,
  posttime: number,
  detected: boolean,
  falseReactions: number[],
  decibel: Decibel,
  side: Side,
  hz: HzLevel,
}

export type AudiometryConclusionUnit = {
  unit: number,
  decibel: Decibel,
  side: Side,
  hz: HzLevel,
  line: AudiometryUnitLine[]
}

export enum Event {
  PipStart = 'PIPSTART',
  PipEnd = 'PIPEND',
  Detected = 'DETECTED',
  Undetected = 'UNDETECTED',
  FalseReaction = 'FALSEREACTION',
  Conclusion = 'CONCLUSION',
  Undefined = 'UNDEFINED'
}

export enum Side {
  Left = "L",
  Right = "R",
  None = ""
}


export type PipEvent = {
  time: number;
  event: Event;
  hz: HzLevel;
  decibel: DecibelTarget;
  side: Side;
  hzIndex: number;
  group: number;
}

const undefinedPip: PipEvent = {
  time: 0,
  event: Event.Undefined,
  hz: null,
  decibel: 0,
  side: Side.None,
  hzIndex: 0,
  group: 0
}


interface IAudiometryModel {

  /** The index of the starting point in dbLevelsTest (40). */
  initialDecibelIndex: number

  /** 
   * Creates a Pip event, with logging as an obligatory side effect.
  */
  // createPipEvent(event: Event, decibel?: DecibelTarget, hz?: HzLevel): PipEvent;


  // startTimer(): void;

  // stopTimer(): void;

  tick(msecs: number): void;

  handleReaction(): void

  // INCOMPLETE ...

}

type AudiometryModelParams = {
  onPipStart?: (pipEvent: PipEvent) => void;
  onPipEnd?: () => void;
  onConclusion?: (decibel: DecibelTarget, unit: AudiometryConclusionUnit) => void;
  onTick?: () => void;
  onFalseReaction?: () => void;
  onTrueReaction?: () => void;
  onLog?: (pipEvent: PipEvent) => void;
  onFinished?: () => void;
  onUnit?: (unit: AudiometryUnit) => void;
}

export class AudiometryModel implements IAudiometryModel {

  readonly initialDecibelIndex = 10        // The index of the starting point in dbLevelsTest (40).
  readonly raiseFastThresholdDbIndex = 4   // Up to which index of dbLevelsTest to rise fast if no errors.
  readonly resistanceStrength = 2          // How many mistakes at one level above necessary to avoid rise to that level.
  readonly supportStrength = 2             // How many detected at one level below necessary to avoid drop to that level.
  readonly deepSupportStrength = 1         // How many detected at two level below necessary to avoid drop to that level.
  readonly detectionsToConclude = 2        // How many times a tone should be detected for it to qualify as threshold.
  readonly intervalTimeMin = 1000
  readonly intervalTimeMax = 2500
  readonly pipDuration = 2000 // msecs duration of beeps. Should match length of samples.

  readonly audiometrySequence: HzLevel[] = [1000, 2000, 4000, 6000, 8000, 500, 250, 1000]
  readonly decibelLevels: DecibelTarget[] = DecibelLevels

  onPipStartCallback = (pipEvent: PipEvent) => {}
  onPipEndCallback = () => {}
  onConclusionCallback = (decibel: DecibelTarget, unit: AudiometryConclusionUnit) => {}
  onTickCallback = () => {}
  onFalseReactionCallback = () => {}
  onTrueReactionCallback = () => {}
  onLogCallback = (pipEvent: PipEvent) => {}
  onFinishedCallback = () => {}
  onUnitCallback = (unit: AudiometryUnit) => {} 

  hzIndex = 0
  decibelIndex

  pipLog: PipEvent[] = []
  pip = false

  currentPipDetected = false
  errorsForFrequency = false
  finishedRight = false
  finishedLeft = false

  time = 0 // msecs since player started.
  timeOfLastEvent = 0
  group = 0
  interval = this.intervalTimeMax

  unit = 0 // Reset for sides
  units: { [key: string]: AudiometryUnit[] } = {
    [Side.Left]: [],
    [Side.Right]: []
  }
  conclusions: { [key: string]: AudiometryConclusionUnit[] } = {
    [Side.Left]: [],
    [Side.Right]: []
  }
  unitFalseReactions: number[] = []
  line: AudiometryUnitLine[] = []


  constructor(params: AudiometryModelParams) {
    if (params.onPipStart) this.onPipStartCallback = params.onPipStart
    if (params.onPipEnd) this.onPipEndCallback = params.onPipEnd
    if (params.onConclusion) this.onConclusionCallback = params.onConclusion
    if (params.onTick) this.onTickCallback = params.onTick
    if (params.onFalseReaction) this.onFalseReactionCallback = params.onFalseReaction
    if (params.onTrueReaction) this.onTrueReactionCallback = params.onTrueReaction
    if (params.onLog) this.onLogCallback = params.onLog
    if (params.onFinished) this.onFinishedCallback = params.onFinished
    if (params.onUnit) this.onUnitCallback = params.onUnit
    this.resetInterval()
    this.decibelIndex = this.initialDecibelIndex
  }

  get hz(): HzLevel {
    return this.audiometrySequence[this.hzIndex]
  }

  get decibel(): DecibelTarget {
    return this.decibelLevels[this.decibelIndex]
  }

  get side(): Side {
    if (!this.finishedRight) { return Side.Right }
    else if (!this.finishedLeft) { return Side.Left }
    else { return Side.None }
  }

  get finished() {
    return this.finishedLeft && this.finishedRight
  }

  get previous(): { pipEvent: PipEvent, decibelIndex: number, detectedSum: number, undetectedSum: number } {
    const pipEvent: PipEvent = this.pipLog.find(p => (p.event === Event.Detected || p.event === Event.Undetected) && p.side === this.side) || { ...undefinedPip }
    const decibelIndex = this.decibelLevels.findIndex(v => v === pipEvent.decibel)
    const detectedSum = this.pipLog.filter(p => p.hzIndex === pipEvent.hzIndex && p.decibel === this.decibelLevels[decibelIndex] && p.side === this.side && p.event === Event.Detected).length
    const undetectedSum = this.pipLog.filter(p => p.hzIndex === pipEvent.hzIndex && p.decibel === this.decibelLevels[decibelIndex] && p.side === this.side && p.event === Event.Undetected).length

    return {
      pipEvent,
      decibelIndex,
      detectedSum,
      undetectedSum
    }
  }

  private resetInterval(): void {
    this.interval = Math.floor(Math.random() * (this.intervalTimeMax - this.intervalTimeMin + 1) + this.intervalTimeMin)
  }

  private enforce({ set, adj, rule } : { set?: number | undefined, adj?: number | undefined, rule: string}): void {
    if (set !== undefined) {
      this.decibelIndex = set
    }
    else if (adj !== undefined) {
      this.decibelIndex = this.decibelIndex + adj
    }
    // console.log(rule, set, adj, this.decibelIndex)
  }

  public tick(msecs: number) {
    this.time = msecs // this.time += 10
    this.onTickCallback()
    if (this.pip && this.time >= this.timeOfLastEvent + this.pipDuration) {
      this.pipEnd()
    }
    else if (!this.pip && this.time >= this.timeOfLastEvent + this.interval && !this.finished) {
      this.pipStart()
    }
    else if (this.currentPipDetected === true) {
      this.pipEnd()
    }
  }

  private createPipEvent(params: { event: Event, decibel?: DecibelTarget, hz?: HzLevel }): PipEvent {
    const pipEvent = {
      time: this.time,
      event: params.event,
      hz: params.hz !== undefined ? params.hz : this.hz,
      hzIndex: this.hzIndex,
      decibel: params.decibel || this.decibel,
      side: this.side,
      group: this.group,
    }
    if (params.event === Event.PipEnd) {
      this.group += 1
    }
    this.pipLog.unshift(pipEvent)
    this.onLogCallback(pipEvent)
    return pipEvent
  }

  private pipStart(): void {
    this.pip = true
    this.timeOfLastEvent = this.time
    const pipEvent = this.createPipEvent({ event: Event.PipStart })
    this.onPipStartCallback(pipEvent)
  }

  public handleReaction(): void {
    if (!this.pip) {
      this.createPipEvent({ event: Event.FalseReaction, hz: null })
      this.unitFalseReactions.push(-(this.time - this.timeOfLastEvent))
      this.onFalseReactionCallback()
    }
    else if (this.pip && !this.currentPipDetected) {
      this.currentPipDetected = true
      this.createPipEvent({ event: Event.Detected })
      this.onTrueReactionCallback()
    }
  }

  private pipEnd(): void {

    // unit
    const unit: AudiometryUnit = {
      unit: this.unit,
      pretime: this.interval,
      posttime: this.time - this.timeOfLastEvent,
      detected: this.currentPipDetected,
      falseReactions: this.unitFalseReactions,
      decibel: this.decibel,
      hz: this.hz,
      side: this.side,
    }
    this.units[this.side].push(unit)
    this.onUnitCallback(unit)
    this.line.push([this.unit, this.decibel])
    this.unit = this.unit + 1
    this.unitFalseReactions = []
    
        


    this.pip = false
    this.timeOfLastEvent = this.time
    if (!this.currentPipDetected) {
      this.errorsForFrequency = true
      this.createPipEvent({ event: Event.Undetected })
    }
    this.createPipEvent({ event: Event.PipEnd })
    this.resetInterval()
    this.currentPipDetected = false
    this.onPipEndCallback()
    this.prepNextPipOrConclude()
  }

  private prepNextPipOrConclude() {
    if (this.previous.pipEvent.event === Event.Undefined) {
      throw new Error()
    }

    const canHaveResistance = this.previous.decibelIndex > 0
    const canHaveSupport = this.previous.decibelIndex < this.decibelLevels.length - 1
    const canHaveDeepSupport = this.previous.decibelIndex < this.decibelLevels.length - 2

    const resistance = !canHaveResistance ? 0 : this.pipLog.filter(p => p.hzIndex === this.previous.pipEvent.hzIndex && p.side === this.side && p.event === Event.Undetected && p.decibel === this.decibelLevels[this.previous.decibelIndex - 1]).length
    const support = !canHaveSupport ? 0 : this.pipLog.filter(p => p.hzIndex === this.previous.pipEvent.hzIndex && p.side === this.side && p.event === Event.Detected && p.decibel === this.decibelLevels[this.previous.decibelIndex + 1]).length
    const deepSupport = !canHaveDeepSupport ? 0 : this.pipLog.filter(p => p.hzIndex === this.previous.pipEvent.hzIndex && p.side === this.side && p.event === Event.Detected && p.decibel === this.decibelLevels[this.previous.decibelIndex + 2]).length

    /* ===== Conclusions
     - If Ascended to highest point and detected sufficiently.
     - If detected sufficiently and level above has sufficient resistance.
     - If insuffcient detections at current level, despite attempts, but has support, conclude for support.
     - If descended to lowest point and sufficiently undetected.
     */

    if (this.previous.detectedSum >= this.detectionsToConclude && this.previous.decibelIndex === 0) {
      this.enforce({rule: "[1] If Ascended to highest point and detected sufficiently."}) 
      this.conclusion(this.decibelLevels[this.previous.decibelIndex])
    }
    else if (this.previous.detectedSum >= this.detectionsToConclude && canHaveResistance && resistance >= this.resistanceStrength) {
      this.enforce({rule: "[2] If detected sufficiently and level above has sufficient resistance."}) 
      this.conclusion(this.decibelLevels[this.previous.decibelIndex])
    }
    else if (this.previous.detectedSum < this.detectionsToConclude && this.previous.undetectedSum >= this.detectionsToConclude && canHaveSupport && support >= this.supportStrength) {
      this.enforce({rule: "[3] If insuffcient detections at current level, despite attempts, but has support, conclude for support."}) 
      this.conclusion(this.decibelLevels[this.previous.decibelIndex + 1])
    }
    else if (this.previous.detectedSum < this.detectionsToConclude && this.previous.undetectedSum >= this.detectionsToConclude && this.previous.decibelIndex === this.decibelLevels.length - 1) {
      this.enforce({rule: "[4] If descended to lowest point and sufficiently undetected."}) 
      this.conclusion(this.decibelLevels[this.previous.decibelIndex])
    }

    /* ==== Prepare next Pip (Valid conclusion not caught.)
     Sequentially validate until first true:
     - If detected at uppermost point of spectrum, repeat.
     - If detected and no errors for frequency, and not upper part of spectrum, rise more.
     - If detected and no errors for frequency rise normally. ***
     - If detected and errors for frequency, and sufficient resistance, repeat current.
     - If detected and errors for frequency, without sufficient resistance, rise normally. ****
     - If undetected and support, repeat current.
     - If undetected without support, but deep support, drop one. ****
     - If undetected at lowest level, repeat current.
     - If undetected and next to lowest level, drop one. ****
     - If undetected without support or deep support, drop two. ***
     */


    else if (this.previous.pipEvent.event === Event.Detected && this.previous.decibelIndex === 0) {
      this.enforce({rule: "[5] If detected at uppermost point of spectrum, repeat."}) 

    }
    else if (this.previous.pipEvent.event === Event.Detected && !this.errorsForFrequency) {
      if (this.decibelIndex >= 4) {
        this.enforce({adj: -4, rule: "[6-A] If detected and no errors for frequency, and not upper part of spectrum, rise more."}) 
      } else {
        this.enforce({set: 0, rule: "[6-B] If detected and no errors for frequency, and upper part of spectrum, avoid index overflow."}) 
      } 
    }

    else if (this.previous.pipEvent.event === Event.Detected && this.errorsForFrequency) {
      if (canHaveResistance && resistance >= this.resistanceStrength) {
        this.enforce({rule: "[7-A] If detected and errors for frequency, and sufficient resistance, repeat current."})
      }
      else {
        if (this.decibelIndex >= 2) { 
          this.enforce({adj: -2, rule: '[7-B] If detected and errors for frequency, without sufficient resistance, rise normally.'})
        } else {
          this.enforce({set: 0, rule: '[7-C] If detected and errors for frequency, without sufficient resistance, and upper part of spectrum, avoid index overflow.'})
          } 
      }
    }

    else if (this.previous.pipEvent.event === Event.Undetected) {
      if (canHaveSupport && support >= this.supportStrength) {
        this.enforce({rule: "[8-A] If undetected and support, repeat current."})
      }
      else if (canHaveDeepSupport && deepSupport >= this.deepSupportStrength) {
        this.enforce({adj: 1, rule: "[8-B] If undetected without support, but deep support, drop one."})
      }
      else if (this.previous.decibelIndex === this.decibelLevels.length - 1) {
        this.enforce({rule: "[8-C] If undetected at lowest level, repeat current."})
      }
      else if (this.previous.decibelIndex === this.decibelLevels.length - 2) {
        this.enforce({adj: 1, rule: "[8-D] If undetected and next to lowest level, drop one."})
      }
      else {
        this.enforce({adj: 2, rule: "[8-E] If undetected without support or deep support, drop two."})
      }
    }


  }

  private conclusion(decibel: DecibelTarget) {

        // unit
        const unit: AudiometryConclusionUnit = {
          unit: this.unit,
          decibel: decibel,
          line: this.line,
          side: this.side,
          hz: this.hz,
        }
        this.conclusions[this.side].push(unit)
        this.unit = this.unit + 1
        this.line = []


    this.onConclusionCallback(decibel, unit)

    this.createPipEvent({ event: Event.Conclusion, decibel: decibel })

    if (this.hzIndex === this.audiometrySequence.length - 1) {

      // Current side is finished:
      if (this.side === Side.Right) {
        this.finishedRight = true
      } else {
        this.finishedLeft = true
      }

      if (this.finishedRight && this.finishedLeft) {
        // console.log("FINISH EVERYTHING")
        this.onFinishedCallback()
      }

      else {
        // console.log("TEST OTHER SIDE")
        this.decibelIndex = this.initialDecibelIndex
        this.hzIndex = 0
        this.errorsForFrequency = false
        this.unit = 0
      }
    }
    else {
      // console.log("NEXT FREQUENCY")
      this.decibelIndex = this.initialDecibelIndex
      this.hzIndex += 1
      this.errorsForFrequency = false
    }
  }

}
