import { createSlice } from '@reduxjs/toolkit'

export enum Nav { Menu, Calibration, Pretest, Test, TestDebug, Analyze }

const initialState: {
    nav: Nav
} = {
    nav: Nav.Menu
}

export const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setNav: (state, action) => {
            state.nav = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setNav
} = appSlice.actions

export default appSlice.reducer