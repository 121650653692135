import { createSlice } from '@reduxjs/toolkit'
import { createInitialCalibrationSet } from '../CalibrationModel'
import { Side } from '../AudiometryModel'


const createInitialCalibratorState = () => {
    return {
        schema: createInitialCalibrationSet(),
        meta: {
            complete: 0
        }
    }
}

const initialState: any = createInitialCalibratorState()

export const calibrationSlice = createSlice({
    name: 'calibration',
    initialState: initialState,
    reducers: {
        setCalibration: (state, action) => {

            const { hz, side, calibrationConstant, linearRef, decibelRef, decibelHLRef } = action.payload

            state.schema[hz][side] = calibrationConstant
            state.meta = {
                ...state.meta,
                [hz]: {
                    ...state.meta[hz],
                    [side]: {
                        linearRef: linearRef,
                        decibelRef: decibelRef,
                        decibelHLRef: decibelHLRef,
                    }
                }
            }

            state.meta.complete = (() => {
                let i = 0
                let sum = 0
                Object.keys(state.schema).forEach((hz: any) => {
                    sum = sum + 2
                    if (state.schema[hz][Side.Left] !== 0) { i++ }
                    if (state.schema[hz][Side.Right] !== 0) { i++ }
                })
                return (i/sum) * 100
            })()
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setCalibration,
} = calibrationSlice.actions

export default calibrationSlice.reducer