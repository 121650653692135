import React from 'react';
import { Howl } from 'howler';

import sample125 from './sounds/sin_125Hz_-26dBFS_2s.mp3';
import sample250 from './sounds/sin_250Hz_-26dBFS_2s.mp3';
import sample500 from './sounds/sin_500Hz_-26dBFS_2s.mp3';
import sample1000 from './sounds/sin_1000Hz_-26dBFS_2s.mp3';
import sample2000 from './sounds/sin_2000Hz_-26dBFS_2s.mp3';
import sample3000 from './sounds/sin_3000Hz_-26dBFS_2s.mp3';
import sample4000 from './sounds/sin_4000Hz_-26dBFS_2s.mp3';
import sample6000 from './sounds/sin_6000Hz_-26dBFS_2s.mp3';
import sample8000 from './sounds/sin_8000Hz_-26dBFS_2s.mp3';
import sample16000 from './sounds/sin_16000Hz_-26dBFS_2s.mp3';

import { LinearVolume } from './CalibrationModel';
import { HzLevel, Side } from './AudiometryModel';


export default function useSoundInterface() {

    const [sound125] = React.useState(new Howl({ src: sample125, preload: true, pool: 15 }))
    const [sound250] = React.useState(new Howl({ src: sample250, preload: true, pool: 15 }))
    const [sound500] = React.useState(new Howl({ src: sample500, preload: true, pool: 15 }))
    const [sound1000] = React.useState(new Howl({ src: sample1000, preload: true, pool: 15 }))
    const [sound2000] = React.useState(new Howl({ src: sample2000, preload: true, pool: 15 }))
    const [sound3000] = React.useState(new Howl({ src: sample3000, preload: true, pool: 15 }))
    const [sound4000] = React.useState(new Howl({ src: sample4000, preload: true, pool: 15 }))
    const [sound6000] = React.useState(new Howl({ src: sample6000, preload: true, pool: 15 }))
    const [sound8000] = React.useState(new Howl({ src: sample8000, preload: true, pool: 15 }))
    const [sound16000] = React.useState(new Howl({ src: sample16000, preload: true, pool: 15 }))

    const samples = {
        125: sound125,
        250: sound250,
        500: sound500,
        1000: sound1000,
        2000: sound2000,
        3000: sound3000,
        4000: sound4000,
        6000: sound6000,
        8000: sound8000,
        16000: sound16000
    }


    function playPip( hz: HzLevel, volume: LinearVolume, side: Side, loop: boolean = false ): void {
        if (hz === null || side === Side.None) {
            throw new Error('Bad arguments for playPip')
        }
        stopPip()
        samples[hz].volume(volume)
        samples[hz].loop(loop)
        samples[hz].stereo(side === Side.Right ? 1 : -1)
        samples[hz].play()
    }


    function stopPip(): void {
        samples[125].stop()
        samples[250].stop()
        samples[500].stop()
        samples[1000].stop()
        samples[2000].stop()
        samples[3000].stop()
        samples[4000].stop()
        samples[6000].stop()
        samples[8000].stop()
        samples[16000].stop()
    }


    return {
        playPip,
        stopPip
    }

}