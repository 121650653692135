
import {
    Box, Text, Button, Stack,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure,
} from "@chakra-ui/react"
import { useSelector } from 'react-redux'
import ReactModal from 'react-modal';
import AudiogramComponent from './AudiogramComponent';
import { Side } from "./AudiometryModel";
import useWindowDimensions from "./useWindowDimensions";
import LydgrenUnitChart from './LydgrenUnitChart';
import PlayerController from "./player/PlayerController";
import PlayerLogic from './player/PlayerLogic';
import PlayerUI from "./player/PlayerUI";
import React from "react";
import CalibratorModal from "./calibrator/CalibratorModal";
import useSoundInterface from './useSoundInterface';

ReactModal.setAppElement('#root');


export default function Layout() {

    const backgroundColor = "#DDEEEC"
    const { height, width } = useWindowDimensions();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { playPip, stopPip } = useSoundInterface()
    const meta = useSelector((state: any) => state.calibration.meta)

    const audiogramDivWidth = 440

    const hScale = height > 1200 ? 1 : height / 1200
    const wScale = width > 1000 ? 1 : width / 1000

    const scale = Math.min(hScale, wScale)


    return (
        <React.Fragment>

            <ReactModal
                isOpen={isOpen}
                contentLabel="Calibration Modal"
            >

                <Box
                    minW={`${width - 122}px`}
                    >


                    <Stack
                        transform={`scale(${scale})`}
                        transformOrigin="top"

                    >
                        <Stack direction="row"
                            w="100%"
                            justifyContent="space-between">
                            <Text fontSize="lg">Calibration</Text>

                        </Stack>

                        <CalibratorModal
                            playPip={playPip}
                            stopPip={stopPip}
                        />

                        <Stack direction="row" w="100%" justifyContent="space-between">
                            <Text fontSize="lg"></Text>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </Stack>


                    </Stack>



                </Box>



            </ReactModal>

            <PlayerLogic
                playPip={playPip}
                stopPip={stopPip}
            />

            <Box
                position="fixed"
                left="0"
                top="0"
                bg={'#fff'}
                minH="100vh"
                // minW={`${100/scale}vw`}
                w={`${width / scale}px`}
                transform={`scale(${scale})`}
                transformOrigin={"top left"}
            >



                <Stack
                    position="absolute"
                    left="0"
                    top="0"
                    bg={'#fff'}
                    minH="100vh"
                    w={`${width / scale}px`}
                    zIndex={"-1"}
                    justify="space-between"
                    spacing={0}



                // scale={scaleFactor}
                >

                    <Stack
                        direction="row"
                        h={`${(height / scale) - (audiogramDivWidth * 2)}px`}
                        w={`${width / scale}px`}
                        spacing={0}
                        bg={backgroundColor}


                    >
                        <Stack
                            p="1rem"
                            justifyContent="space-between"
                            spacing={0}
                            w={`${audiogramDivWidth}px`}
                        >

                            <Text fontSize="lg"><strong>Lydgren</strong> audiometry proof of concept</Text>


                            <PlayerController />
                        </Stack>

                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            minW={`${(width - (audiogramDivWidth)) / scale}px`}
                            p="2rem"
                        >

                            <Box>

                                {meta.complete === 100 ? (
                                    <Alert status='success'>
                                        <AlertIcon />
                                        <AlertTitle>Calibration complete</AlertTitle>
                                    </Alert>
                                ) : (
                                    <Alert status='warning'>
                                        <AlertIcon />
                                        <AlertTitle>Uncalibrated system!</AlertTitle>
                                        <AlertDescription>Results are invalid.</AlertDescription>
                                        <Button
                                            ml="1rem"
                                            variant="outline"
                                            colorScheme={"orange"}
                                            size="sm"
                                            onClick={onOpen}
                                        >Fix this</Button>
                                    </Alert>
                                )}




                            </Box>

                            <PlayerUI />


                        </Stack>

                    </Stack>



                    <Stack
                        borderTop="1px solid lightgray"
                        spacing="0"
                    >


                        <Stack
                            direction="row"
                            alignItems="center"
                            p="1rem"
                            h={`${440}px`}


                        >


                            <AudiogramComponent side={Side.Right} />

                            <Stack>
                                <Text
                                    fontSize="xs"
                                    textTransform="uppercase"
                                    ml="2rem"
                                >Lydgren progress chart</Text>
                                <LydgrenUnitChart
                                    side={Side.Right}
                                    width={(width / scale) - audiogramDivWidth} />
                            </Stack>


                        </Stack>

                        <Stack
                            direction="row"
                            alignItems="center"
                            p="1rem"
                            h={`${440}px`}
                        >

                            <AudiogramComponent side={Side.Left} />
                            <Stack>
                                <Text
                                    fontSize="xs"
                                    textTransform="uppercase"
                                    ml="2rem"
                                >Lydgren progress chart</Text>
                                <LydgrenUnitChart
                                    side={Side.Left}
                                    width={(width / scale) - audiogramDivWidth} />
                            </Stack>
                        </Stack>
                    </Stack>

                </Stack>


            </Box>
        </React.Fragment>
    )
}