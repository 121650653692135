import { DecibelTarget, HzLevel, Side, Decibel, DecibelLevels, HzLevels } from './AudiometryModel';

type DecibelSPL = number

export type HzCalibration = {
    [Side.Left]: number;
    [Side.Right]: number;
}

export type LinearVolume = number
export type OutputVolume = {
    decibelTarget: DecibelTarget,
    hz: HzLevel,
    side: Side,
    decibelHearing: Decibel,
    decibelCalibrated: Decibel,
    linear: LinearVolume
}


export const createInitialCalibrationSet = () => {
    let ret: any = {}
    HzLevels.forEach((hz: any) => {
        ret[hz] = {
            [Side.Left]: 0,
            [Side.Right]: 0,
            target: CalibrationModel.calibrationTargets[hz]
        }
    })
    return ret
}

export type CalibrationSet = { [key: number]: HzCalibration }

/**
    dBTarget        - The audiogram dB-level to test. (Range 120 to -10.)
    
    dBHearing       - dBTarget corrected for hearing level. (Headphone type specific constants for each hz.)
    dBCalibrated    - dbHearing corrected for specific headphone instance, including side differences in speakers.
 
                    dBCalibrated results in a value in the estimate range of 0 (very loud) and -100 (very silent)
                    If dBCalibrated as passed as an argument to convertDecibelToLinear, it should be more negative
                    than -100, as it will result in a value of 0.00000x, which AVAudioPlayer will interpret as no sound.
 
                    Dynamic adjustment according to system level should happen at this level, probably.
 
    dBFinal         - dbCalibrated corrected for sample properties. (Presumes coupling with given sample at this level.)
                    samples -6dBFS are incidentially calibrated as 0. silent samples are -26dBFS, which indicates a correction of + 20.
 
 
    linearVol       - The linear volume value, passable to AVAudioPlayer, after conversion with convertDecibelToLinear.
 
 
 */
export class CalibrationModel {

    protected calibrationSet: any

    static hearingLevelCorrectionsMap: { [key: number]: Decibel } = {
        125: 45,
        250: 25.5,
        500: 11.5,
        1000: 7,
        2000: 9,
        3000: 10,
        4000: 9.5,
        6000: 15.5,
        8000: 11.8
    }

    static calibrationTargets: { [key: number]: DecibelSPL } = {
        125: 90,
        250: 90,
        500: 70,
        1000: 70,
        2000: 70,
        3000: 70,
        4000: 70,
        6000: 75,
        8000: 75,
    }

    static samplesCorrection = 0 // 20 // Should not be relevant when directly calibrated?

    constructor(calibrationSet?: CalibrationSet) {
        this.calibrationSet = calibrationSet || createInitialCalibrationSet()
    }

    calculateVolumeForSoundInterface(hz: HzLevel, decibel: DecibelTarget, side: Side): OutputVolume {
        if (side === Side.None) {
            throw new Error("Cannot calculate volume for no-side.")
        }
        else if (hz === null) {
            throw new Error("Cannot calculate volume for null hz.")
        }
        else {
            const decibelHearing: Decibel = decibel + CalibrationModel.hearingLevelCorrectionsMap[hz]
            const decibelCalibrated: Decibel = this.calibrationSet ? decibelHearing + this.calibrationSet[hz][side] : decibelHearing
            const linear: LinearVolume = CalibrationModel.convertDecibelToLinear(decibelCalibrated)
            const output: OutputVolume = {
                decibelTarget: decibel,
                hz: hz,
                side: side,
                decibelHearing: decibelHearing,
                decibelCalibrated: decibelCalibrated,
                linear: linear
            }
            console.log('output', output)
            return output
        }
    }

    static convertDecibelToLinear(decibel: Decibel): LinearVolume {
        return Math.pow(10.0, ((decibel)/20))
    }

    static convertLinearToDecibel(linear: LinearVolume): Decibel {
        return 20 * Math.log10(linear)
    }


    static getOutputVolume(decibel: Decibel, calibrationConstant: Decibel): LinearVolume {
        const volume = this.convertDecibelToLinear(decibel - calibrationConstant)
        return volume
    }

    static estimateCalibrationConstant(linearRef: LinearVolume, decibelRef: Decibel, decibelHLRef: Decibel): Decibel {
        const decibel = CalibrationModel.convertLinearToDecibel(linearRef)
        return Math.abs(decibel - (decibelRef + decibelHLRef))
    }

    /**
     Given a linear and decibel value as references, for a Hz. Like:
     linearRef: 0.75
     decibelRef: 115

     A calibration constant is calculated, that corresponds to the value decibelRef
     must be adjusted with, order for a simple conversion of 115 to linear to be 0.75.
     This calibration constant is (simplified) reusable within a Hz. In our example:
     calibrationConstant: 117.5
     (decibelRef - calibrationConstant: -2.5, which converts to 0.75)

     The next step is to use this information to estimate linear values for decibel test levels:
     decibelLevel: 40 (for example)

     When presenting a test for 40, we are actually testing for a different decibel (hearing level corrected)
     In addition to this, neccessary adjustments must be made for the sample files. In our case:
     decibelCorrected: 105 (40 + 45 + 20)

     We can then get the linear value after applying the calibration constant:
     decibelCalibrated: -12.5 (105 - 117.5)
     linear: 0.236

     */
    static estimateCalibrationSetForHz(linearRef: LinearVolume, decibelRef: Decibel, hz: HzLevel): any {
        if (!hz) {
            throw new Error("Cannot make calibration set without knowing hz level")
        }
        const calibrationConstant = CalibrationModel.estimateCalibrationConstant(linearRef, decibelRef, 0)

        let ret: { [key: Decibel]: LinearVolume} = {}
        
        DecibelLevels.forEach((decibelLevel: Decibel) => {
            const decibelCorrected = decibelLevel + CalibrationModel.hearingLevelCorrectionsMap[hz] + CalibrationModel.samplesCorrection
            const decibelCalibrated = decibelCorrected - calibrationConstant
            const linear = CalibrationModel.convertDecibelToLinear(decibelCalibrated)
            ret[decibelLevel] = linear
        })

        return ret 


    }



}