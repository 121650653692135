import React from 'react';
import { useSelector } from 'react-redux'
import {
    Stack, Text,
    Box,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Input,
    Tooltip,
} from "@chakra-ui/react"
import { CalibrationModel } from '../CalibrationModel';
import { IconButton, Icon } from '@chakra-ui/react'
import {
    HiChevronDoubleDown,
    HiChevronDown,
    HiChevronDoubleUp,
    HiChevronUp,
} from 'react-icons/hi'
import { ImPlay3, ImStop2 } from 'react-icons/im'
import { InfoOutlineIcon, LockIcon, UnlockIcon } from '@chakra-ui/icons'



export default function HzCalibrator(params: any) {

    const {
        hzLevel,
        side,
        lockCalibration,
        unlockCalibration,
        playPip,
        stopPip,
        playing,
    } = params

    const hzSchema = useSelector((state: any) => state.calibration.schema[hzLevel])
    const isLocked = hzSchema[side] !== 0
    const isPlaying = playing[0] === hzLevel && playing[1] === side

    const toVolume = (n: number) => n / 10000
    const toSlider = (n: number) => n * 10000

    const [sliderValue, _setSliderValue] = React.useState(toSlider(0.5))
    const [decibelValue, _setDecibelValue] = React.useState(hzSchema.target)
    const [decibelHLValue, _setDecibelHLValue] = React.useState(0)
    const [debug] = React.useState(false)

    const cal = CalibrationModel.estimateCalibrationSetForHz(toVolume(sliderValue), hzSchema.target, hzLevel)

    const togglePip = () => {
        if (isPlaying) {
            stopPip()
        }
        else {
            playPip(hzLevel, toVolume(sliderValue), side)
        }
    }

    const handleLockCalibration = () => {
        if (isLocked) {
            unlockCalibration(hzLevel, side)
        }
        else {
            lockCalibration(hzLevel, side, toVolume(sliderValue), decibelValue, decibelHLValue)

        }
    }


    const incrementValue = (n: number) => {
        setSliderValue(sliderValue + n)
    }

    const setSliderValue = (n: number) => {
        if (isLocked) {
            return
        }
        _setSliderValue(n)
        if (playing[0] === hzLevel) {
            playPip(hzLevel, toVolume(n), side)
        }

    }

    const setDecibelValue = (n: number) => {
        if (!isLocked) {
            _setDecibelValue(n)
        }
    }

    const setDecibelHLValue = (n: number) => {
        if (!isLocked) {
            _setDecibelHLValue(n)
        }
    }


    return (
        <Stack
            //m="1rem"
            p="0.4rem"
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={"0.4rem"}
            >

                <Stack
                    minW="4rem"
                    h="4rem"
                    justifyContent="space-between"
                >
                    <Text fontSize="sm">{hzLevel} Hz</Text>
                    <Text fontSize="xs" color="gray.400" >{toVolume(sliderValue).toFixed(4)}</Text>
                    <Box>
                        {/* <Button
                            size="xs"
                            variant="ghost"
                            onClick={() => setDebug(!debug)}
                            color="gray.300"

                        >Debug</Button> */}
                    </Box>

                </Stack>

                <Stack
                    h="4rem"
                    justifyContent={"center"}
                >
                    <Slider
                        aria-label='slider-ex-1'
                        value={sliderValue}
                        min={1}
                        max={toSlider(1)}
                        focusThumbOnChange={false}
                        onChange={(v) => setSliderValue(v)}
                        colorScheme={isLocked ? 'green' : isPlaying ? 'yellow' : 'blue'}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>

                    {/* <Text>Slidervalue: {toVolume(sliderValue).toFixed(4)}</Text> */}

                    <Stack
                        direction="row"
                        minW="6rem"
                        justifyContent="space-between"
                        alignItems="center"

                    >
                        <Stack direction="row">
                            <IconButton
                                variant="ghost"
                                size="sm"
                                aria-label='--'
                                icon={<Icon as={HiChevronDoubleDown} />}
                                onClick={() => incrementValue(-10)} />
                            <IconButton
                                variant="ghost"
                                size="sm"
                                aria-label='-'
                                icon={<Icon as={HiChevronDown} />}
                                onClick={() => incrementValue(-1)} />
                        </Stack>



                        <Stack direction="row">

                            <IconButton
                                variant="ghost"
                                size="sm"
                                aria-label='++'
                                icon={<Icon as={HiChevronUp} />}
                                onClick={() => incrementValue(1)} />
                            <IconButton
                                variant="ghost"
                                size="sm"
                                aria-label='+'
                                icon={<Icon as={HiChevronDoubleUp} />}
                                onClick={() => incrementValue(10)} />
                        </Stack>
                    </Stack>
                </Stack>




                <Stack
                    justifyContent="space-between"
                    pl="1rem"
                >

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-between"}

                    >
                        <Stack direction="row"
                            alignItems="center">
                            <Text fontSize="sm">dB</Text>
                            <Tooltip
                                label="Calibration is presumed done at hearing level. In which case the 'db HL' field should be 0. If calibration is done at SPL, enter the hearing level correction values for you specific headset and frequency in the 'db HL'-field.">
                                <InfoOutlineIcon color="gray.500" />
                            </Tooltip>
                        </Stack>

                        <Input
                            size="sm"
                            maxW="4rem"
                            value={decibelValue}
                            onChange={e => setDecibelValue(Number(e.target.value))}
                            isDisabled={isLocked}
                        />

                    </Stack>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-between"}
                    >



                        <Text fontSize="sm">dB HL</Text>

                        <Input
                            size="sm"
                            maxW="4rem"
                            value={decibelHLValue}
                            onChange={e => setDecibelHLValue(Number(e.target.value))}
                            isDisabled={isLocked}
                        />


                    </Stack>



                </Stack>


                <IconButton
                    aria-label='Toggle play'
                    variant="ghost"
                    colorScheme={isPlaying ? 'yellow' : 'gray'}
                    // bgColor={isPlaying ? 'yellow.200' : ''}
                    // _hover={{
                    //     bgColor: isPlaying ? 'yellow.300' : ''
                    // }}
                    icon={<Icon
                        as={isPlaying ? ImStop2 : ImPlay3}
                        h="1rem"
                        w="1rem"
                    />}
                    onClick={togglePip} />



                <IconButton
                    icon={isLocked ? <LockIcon h="1rem"
                        w="1rem" /> : <UnlockIcon h="1rem"
                            w="1rem" />}
                    aria-label='..'
                    onClick={handleLockCalibration}
                    colorScheme={isLocked ? 'green' : 'gray'}
                    variant="ghost"

                />


            </Stack>

            {debug ? (
                <Text
                    fontSize="xs"
                    whiteSpace={"pre"}
                    color="gray.500"
                >{JSON.stringify(cal, null, 2)}</Text>
            ) : null}
        </Stack>
    )


}

