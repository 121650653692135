import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    setCalibration,
} from './calibrationSlice'
import {
    Stack, Text, Heading, Progress, Button, Wrap

} from "@chakra-ui/react"
import { CalibrationModel, LinearVolume } from '../CalibrationModel';
import { MdOutlineHeadphones } from 'react-icons/md'

import { Decibel, HzLevel, Side, HzLevels } from '../AudiometryModel';
import HzCalibrator from './HzCalibrator';



export default function CalibratorModal(params: {playPip: Function, stopPip: Function}) {


    const dispatch = useDispatch()
    const schema = useSelector((state: any) => state.calibration.schema)
    const meta = useSelector((state: any) => state.calibration.meta)
    const { playPip, stopPip } = params
    const [playing, setPlaying] = React.useState([0, Side.None])


    const onPlayPip = (hz: HzLevel, volume: LinearVolume, side: Side) => {
        setPlaying([Number(hz), side])
        playPip(hz, volume, side, true)
    }

    const onStopPip = () => {
        setPlaying([0, Side.None])
        stopPip()
    }

    const onLockCalibration = (hz: HzLevel, side: Side, linearRef: LinearVolume, decibelRef: Decibel, decibelHLRef: Decibel) => {
        const calibrationConstant = CalibrationModel.estimateCalibrationConstant(linearRef, decibelRef, decibelHLRef)
        dispatch(setCalibration({ hz, side, calibrationConstant, linearRef, decibelRef, decibelHLRef }))
    }

    const onUnlockCalibration = (hz: HzLevel, side: Side) => {
        dispatch(setCalibration({ hz, side, calibrationConstant: 0 }))
    }

    const setMockCalibration = () => {
        HzLevels.forEach((hz: HzLevel) => {
            if (hz === null) return
            [Side.Left, Side.Right].forEach(side => {
                onLockCalibration(hz, side, 0.9, schema[hz].target, 0)
            })
        })
    }

    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            onStopPip()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    if (!schema) {
        return null
    }

    return (
        <Stack
            spacing="0"
            alignItems="center">

            {/* <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
            <Heading as='h4' fontSize="1rem" py="1rem">CALIBRATION</Heading>
        <Button size="xs" variant="outline" onClick={setMockCalibration}>Pretend calibration is done</Button>
            </Stack> */}


            <Stack direction="row" alignItems={"center"} justify="space-between" w="100%">
                <Progress
                    value={meta.complete}
                    colorScheme="teal"
                    hasStripe
                    w="70%"
                    bg="white"
                />
                <Text>{meta.complete.toFixed(0)}% complete</Text>
                <Button size="xs" variant="outline" onClick={setMockCalibration}>Pretend calibration is done</Button>
            </Stack>






            <Wrap>


                <Stack>
                    <Stack direction="row" alignItems="center" pt="2rem">
                        <MdOutlineHeadphones size="1.6rem" />
                        <Heading as='h3' fontSize="1.6rem">L</Heading>
                    </Stack>

                    <Stack
                        my="1rem"
                        py="1rem"
                        border="1px solid"
                        borderColor="gray.400"
                        borderRadius="lg"
                        bg="white"
                    >

                        {Object.keys(schema).map((hz: any) => {
                            return (
                                <HzCalibrator
                                    key={hz}
                                    hzLevel={Number(hz)}
                                    side={Side.Left}
                                    playPip={onPlayPip}
                                    stopPip={onStopPip}
                                    playing={playing}
                                    lockCalibration={onLockCalibration}
                                    unlockCalibration={onUnlockCalibration}
                                />
                            )
                        })
                        }
                    </Stack>
                </Stack>


                <Stack>
                    <Stack direction="row-reverse" alignItems="center" pt="2rem">
                        <MdOutlineHeadphones size="1.6rem" />
                        <Heading as='h3' fontSize="1.6rem">R</Heading>
                    </Stack>

                    <Stack
                        my="1rem"
                        py="1rem"
                        border="1px solid"
                        borderColor="gray.400"
                        borderRadius="lg"
                        bg="white"
                    >

                        {Object.keys(schema).map((hz: any) => {
                            return (
                                <HzCalibrator
                                    key={hz}
                                    hzLevel={Number(hz)}
                                    side={Side.Right}
                                    playPip={onPlayPip}
                                    stopPip={onStopPip}
                                    playing={playing}
                                    lockCalibration={onLockCalibration}
                                    unlockCalibration={onUnlockCalibration}
                                />
                            )
                        })
                        }
                    </Stack>
                </Stack>


            </Wrap>


        </Stack>
    )


}

