import { createSlice } from '@reduxjs/toolkit'
import { AudiometryConclusionUnit, AudiometryUnit, PipEvent, Side } from '../AudiometryModel'


const initialState: {
    pip: boolean;
    reaction: boolean;
    decibelLabel: string;
    hzLabel: string;
    sideLabel: Side;
    log: PipEvent[];
    units: { [key: string]: AudiometryUnit[] };
    conclusions: { [key: string]: AudiometryConclusionUnit[] };
    play: 'shouldPause' | 'shouldPlay' | '';
    playState: 'pause' | 'play';
} = {
    play: '',
    playState: 'pause',
    pip: false,
    reaction: false,
    decibelLabel: '',
    hzLabel: '',
    sideLabel: Side.None,
    log: [],
    units: {
        [Side.Left]: [],
        [Side.Right]: []
      },
    conclusions: {
        [Side.Left]: [],
        [Side.Right]: []
      }
}

export const playerSlice = createSlice({
    name: 'player',
    initialState: initialState,
    reducers: {
        setPip: (state, action) => {
            state.pip = action.payload
        },
        setPlay: (state, action) => {
            state.play = action.payload
        },
        setPlayState: (state, action) => {
            state.playState = action.payload
        },
        setDecibelLabel: (state, action) => {
            state.decibelLabel = action.payload
        },
        setHzLabel: (state, action) => {
            state.hzLabel = action.payload
        },
        setSideLabel: (state, action) => {
            state.sideLabel = action.payload
        },
        setReaction: (state, action) => {
            state.reaction = action.payload
        },
        unshiftPipEvent: (state, action) => {
            state.log.unshift(action.payload)
        },
        pushUnit: (state, action) => {
            const side = action.payload.side
            state.units[side] = [...state.units[side], action.payload]
        },
        pushConclusion: (state, action) => {
            const side = action.payload.side
            state.conclusions[side] = [...state.conclusions[side], action.payload]
        },
        flushPlayer: (state, action) => {
            return { ...initialState}
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setPip,
    setDecibelLabel,
    setHzLabel,
    setSideLabel,
    setReaction,
    unshiftPipEvent,
    setPlay,
    pushUnit,
    pushConclusion,
    flushPlayer,
    setPlayState,
} = playerSlice.actions

export default playerSlice.reducer