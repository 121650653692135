import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setPlay } from './playerSlice'
import {
    Stack, Text,
    Stat,
    StatLabel,
    StatNumber,
    Center,
} from "@chakra-ui/react"
import { IconButton, Icon } from '@chakra-ui/react'
import { ImPlay2, ImPause } from 'react-icons/im'

export default function PlayerController() {

    const playState = useSelector((state: any) => state.player.playState)
    const decibelLabel = useSelector((state: any) => state.player.decibelLabel)
    const hzLabel = useSelector((state: any) => state.player.hzLabel)
    const sideLabel = useSelector((state: any) => state.player.sideLabel)
    const pip = useSelector((state: any) => state.player.pip)

    const dispatch = useDispatch()

    const handlePlayToggle = () => {
        if (playState === 'play') {
            dispatch(setPlay('shouldPause'))
        }
        else if (playState === 'pause') {
            dispatch(setPlay('shouldPlay'))
        }
    }

    return (

        <Stack // CONTROLLER WRAPPER
            m="1rem"
            p="1rem"
            borderRadius="lg"
            shadow="base"
            bg="white"
            w="400px"
        >

            <Text
                fontSize="xs"
                textTransform="uppercase"
            >Examination controls</Text>

            <Stack // CONTROLLER COMPONENT
                direction="row"
                p="0.5rem"
                justifyContent="space-between"
                minHeight="4.2rem"
            >


                <Center>
                    <IconButton
                        aria-label='Toggle play'
                        icon={<Icon as={playState === 'play' ? ImPause : ImPlay2} fontSize="3rem" />}
                        onClick={handlePlayToggle}
                        variant="ghost"
                    />
                </Center>

                <Center
                    w="8rem"
                    mr="2rem">
                    {pip ? (
                        <Center
                            bg="yellow.200"
                            w="8rem"
                            borderRadius="md"
                        >
                            <Text fontSize="2rem">TONE</Text>
                        </Center>
                    ) : null}

                </Center>


            </Stack>


            <Stack
                direction="row"
                p="0.5rem"
                justifyContent="space-between"
                minHeight="4.6rem"
            >


                <Stat>
                    <StatLabel>Decibel</StatLabel>
                    <StatNumber>{decibelLabel} </StatNumber>
                </Stat>

                <Stat>
                    <StatLabel>Hz</StatLabel>
                    <StatNumber>{hzLabel}</StatNumber>
                </Stat>

                <Stat>
                    <StatLabel>Side</StatLabel>
                    <StatNumber>{sideLabel} &nbsp;</StatNumber>
                </Stat>


            </Stack>



        </Stack>

    )


}