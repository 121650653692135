import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setReaction } from './playerSlice'
import {
    Button, Stack, Text,
} from "@chakra-ui/react"
import { MdHearing } from 'react-icons/md'

export default function PlayerUI() {

    const dispatch = useDispatch()
    const playState = useSelector((state: any) => state.player.playState)

    const handleClick = () => {
        if (playState === 'play') {
            dispatch(setReaction(true))
        }
    }

    return (
        <React.Fragment>
  
            <Stack
                m="1rem"
                p="1rem"
                // border="1px solid"
                // borderColor="gray.400"
                borderRadius="lg"
                // bg="white"
                // w="60%"
                minW="260px"
            >

           <Text
                fontSize="xs"
                textTransform="uppercase"
            >Examination input clicker</Text>

                <Button 
                onClick={handleClick} 
                height="10rem"
                colorScheme="blue"
                borderRadius={"xl"}
                disabled={playState !== 'play'}
                >
                    <Stack>
                    <MdHearing size="6rem"/>
                    <Text>I hear a tone!</Text>
                    </Stack>
          
                </Button>
            </Stack>

        </React.Fragment>
    )


}