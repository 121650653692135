import React from 'react';
import { useSelector } from 'react-redux'
import { DecibelLevels, Side, AudiometryConclusionUnit, AudiometryUnit, AudiometryUnitLine, DecibelTestLevels } from './AudiometryModel';
import * as d3 from 'd3';
import { Box, Text, Icon } from "@chakra-ui/react"
import { 
    MdClose as Cross, 
    MdPanoramaFishEye as Circle,
} from "react-icons/md"

const HEIGHT = 340

const consts = {
    padding: 20,
    decibelAxisWidth: 50,
    timeAxisWidth: 40,
    axisTickLength: 6,
    xAxisHeight: 40,
    xAxisTextWidth: 60,
    gutter: 20,
    unitGap: 10,
    shapeSize: 12,
    shapeSizeSmall: 10,
    shapeSizeXSmall: 6,
    blueColor: "blue",
    redColor: "red",
    gridHorizontalColor: "#eee",
    gridVerticalColor: "#ddd",
    timeAxis: [
        [0, 3000],
        [1000, 2000],
        [2000, 1000],
        [3000, 0],
        [4000, -1000],
        [5000, -2000],
        [6000, -3000]
    ]
}

export const FilledCircle = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 16 16" 
        {...props}
    >
         <g >
            <circle cx="8" cy="8" r="8" fill="currentColor"/>
            <circle cx="8" cy="8" r="5" fill="#ffffff"/>
          </g>
        </svg>
    );
  }


interface ILydgrenUnitChart {
    width: number,
    side: Side,
}

export default function LydgrenUnitChart(params: ILydgrenUnitChart) {

    const units = useSelector((state: any) => state.player.units[params.side]) 
    const conclusions = useSelector((state: any) => state.player.conclusions[params.side])

    const widthExtras = consts.decibelAxisWidth + (consts.gutter * 2) + consts.timeAxisWidth + (consts.padding * 2)

    const chartHeight = HEIGHT - consts.xAxisHeight - consts.gutter - (consts.padding * 2)
    const paddedHeight = HEIGHT - (consts.padding * 2)

    const unitBasedWidthOfChart = (units.length + conclusions.length) * consts.unitGap
    const unitBasedWidthFull = unitBasedWidthOfChart + widthExtras

    const fullWidth = unitBasedWidthFull > params.width ? unitBasedWidthFull : params.width
    const paddedWidth = fullWidth - (consts.padding * 2)
    const chartWidth = unitBasedWidthFull > params.width ? unitBasedWidthOfChart : params.width - widthExtras

    const toneColor = params.side === Side.Right ? consts.redColor : consts.blueColor



    const xScale = d3.scaleLinear()
        .domain([0, 10])
        .range([0, 10 * consts.unitGap])

    const yDecibelScale = d3.scaleLinear()
        .domain([DecibelLevels[0], DecibelLevels[DecibelLevels.length - 1]])
        .range([0, chartHeight])

    const yTimeScale = d3.scaleLinear()
        .domain([0, 3000])
        .range([0, chartHeight / 2])

    const components: JSX.Element[] = []
    const paths: any[] = []

    const makePath = (coords: AudiometryUnitLine[]) => {
        let path = ""

        for (let i = 0; i < coords.length; i++) {
            const [unit, decibel] = coords[i]

            if (i === 0) {
                path = "M" // move to
            }
            else {
                path += "L" // line (to x, y)
            }

            path += xScale(unit) + " " + yDecibelScale(decibel)
        }
        return path
    }

    const makeXTicks = () => {
        let ticks = []
        let current = null
        for (let i = 0; i < units.length; i++) {
            const unit = units[i]
            if (unit.hz !== current) {
                const pos = unit.unit
                ticks.push([pos, unit.hz])
                current = unit.hz
            }
        }
        return ticks
    }

    const ticks = makeXTicks()

    conclusions.forEach((unit: AudiometryConclusionUnit) => {

        components.push(<Icon
            key={`conclusion-${unit.unit}`}
            as={params.side === Side.Right ? Circle : Cross}
            style={{
                position: "absolute",
                transform: "translate(-50%, -50%)",
            }}
            h={`${consts.shapeSize}px`} w={`${consts.shapeSize}px`}
            top={`${yDecibelScale(unit.decibel)}px`}
            left={`${xScale(unit.unit)}px`}
            fill={toneColor}
        />)

        components.push(<Text
            key={`conclusion-text-${unit.unit}`}
            position="absolute"
            top={`${yDecibelScale(unit.decibel)}px`}
            left={`${xScale(unit.unit)}px`}
            transform="translate(-50%, 25%)"
            fontSize="10px"
        >
            {unit.decibel}
        </Text>)

        const path = makePath(unit.line)

        paths.push(
            <path 
            key={`conclusionPath-${unit.unit}`}
            d={path}
                stroke={toneColor}
                fill="transparent"
                strokeWidth="1.5" />
        )


    })


    units.forEach((unit: AudiometryUnit) => {

        components.push(
            <Box
                key={`pretime-${unit.unit}`}
                position="absolute"
                left={`${xScale(unit.unit)}px`}
                top={chartHeight / 2}
                h={`${yTimeScale(unit.pretime)}px`}
                transform="translate(-50%, 0)"
                w="3px"
                bgColor="lightgray"
            />
        )

        components.push(
            <Box
                key={`posttime-${unit.unit}`}
                position="absolute"
                left={`${xScale(unit.unit)}px`}
                h={`${yTimeScale(unit.posttime)}px`}
                bottom={`${chartHeight / 2}px`}
                transform="translate(-50%, 0)"
                w="3px"
                bgColor={unit.detected ? 'limegreen' : 'darkgray'}
            />
        )


        unit.falseReactions.forEach((relativeTime => {
            components.push(
                <Box
                    key={`falseReactions-${unit.unit}-${relativeTime}`}
                    position="absolute"
                    left={`${xScale(unit.unit)}px`}
                    top={`${-yTimeScale(relativeTime) + (chartHeight / 2)}px`}
                    h={`${consts.shapeSizeXSmall}px`}
                    w={`${consts.shapeSizeXSmall}px`}
                    bgColor="black"
                    transform="translate(-50%, -50%)"

                />
            )
        }))

  
            components.push(<Icon
                key={`tone-${unit.unit}`}
                as={FilledCircle}
                position="absolute"
                transform="translate(-50%, -50%)"
                h={`${consts.shapeSizeSmall}px`}
                w={`${consts.shapeSizeSmall}px`}
                top={`${yDecibelScale(unit.decibel)}px`}
                left={`${xScale(unit.unit)}px`}
                color={unit.detected ? 'green.400' : 'gray.400'}
                //stroke="white"
                fill="khaki"
                zIndex="100"
    
            />)




    })






    return <Box
        id="padding"
        position="relative"
        bg="white"
        w={fullWidth}
        h={HEIGHT}
    >
        <Box
            id="wrapper"
            position="relative"
            bg="white"
            left={`${consts.padding}px`}
            w={paddedWidth}
            h={paddedHeight}
            top={`${consts.padding}px`}
        >

            <Text
            position="relative"
            top="0"
            >
                {params.side}
            </Text>


            <Box id="grid"
                position="absolute"
                left={`${consts.decibelAxisWidth + consts.gutter}px`}
                top="0"
                width={`${chartWidth}px`}
                height={`${chartHeight}px`}>

                <svg height="100%" width="100%"
                    style={{
                        position: "absolute",
                        overflow: "visible"
                    }}>

                    {DecibelTestLevels.map((decibelLevel, i) => {
                        const y = yDecibelScale(decibelLevel)
                        return <line
                        key={`gridLineY-${decibelLevel}`}
                            y1={y}
                            y2={y}
                            x1={0}
                            x2={chartWidth}
                            stroke={consts.gridHorizontalColor}
                            strokeWidth="1"
                        />
                    })}

                    {ticks.map((tick, i) => {
                        const x = xScale(tick[0])
                        return <line
                        key={`gridLineX-${tick[0]}`}
                            y1={0}
                            y2={chartHeight}
                            x1={x}
                            x2={x}
                            stroke={consts.gridVerticalColor}
                            strokeWidth="1"
                        />
                    })}
                </svg>

            </Box>


            <Box id="decibelAxis"
                position="absolute"
                top="0"
                width={`${consts.decibelAxisWidth}px`}
                height={`${chartHeight}px`}>

                <svg height="100%" width="100%"
                    style={{
                        position: "absolute",
                        overflow: "visible"
                    }}>

                    <line y1={0} y2={chartHeight} x1={consts.decibelAxisWidth - 1} x2={consts.decibelAxisWidth - 1} stroke="currentColor" />

                    {DecibelTestLevels.map((decibelLevel, i) => {
                        const y = yDecibelScale(decibelLevel)
                        return <line
                        key={`decibelLevelLine-${decibelLevel}`}
                            y1={y}
                            y2={y}
                            x1={consts.decibelAxisWidth - consts.axisTickLength}
                            x2={consts.decibelAxisWidth}
                            stroke="currentColor"
                        />
                    })}
                </svg>

                {DecibelTestLevels.map((decibelLevel, i) => {
                    const y = yDecibelScale(decibelLevel)
                    return <Text
                    key={`decibelLevel-${decibelLevel}`}
                        w={`${consts.decibelAxisWidth - consts.axisTickLength}px`}
                        pr="1"
                        color="gray.500"
                        style={{
                            position: "absolute",
                            top: y,
                            transform: "translate(0, -50%)",
                            textAlign: "right",
                            overflow: 'hidden',
                            textOverflow: 'clip',
                            whiteSpace: 'nowrap',
                        }}
                        fontSize="sm"
                    >{decibelLevel}</Text>
                })}

                <Text
                    color="gray.500"
                    position="absolute"
                    // top="0"
                    top={`${chartHeight / 2}px`}

                    left="0"
                    fontSize="sm"
                    transform={"rotate(-90deg) translate(0, -100%) "}
                >Decibel</Text>

            </Box>


            <Box id="timeAxis"
                position="absolute"
                top="0"
                right="0"
                width={`${consts.timeAxisWidth}px`}
                height={`${chartHeight}px`}>

                <svg height="100%" width="100%"
                    style={{
                        position: "absolute",
                        overflow: "visible"
                    }}>

                    <line y1={0} y2={chartHeight} x1={0} x2={0} stroke="currentColor" />

                    {consts.timeAxis.map((time, i) => {
                        const y = yTimeScale(time[0])
                        return <line
                        key={`timeAxisLine-${time[0]}`}
                            y1={y}
                            y2={y}
                            x1={0}
                            x2={consts.axisTickLength}
                            stroke="currentColor"
                        />
                    })}
                </svg>

                {consts.timeAxis.map((time, i) => {
                    const y = yTimeScale(time[0])
                    return <Text
                    key={`timeAxis-${time[0]}`}
                        w={consts.timeAxisWidth - consts.axisTickLength}
                        pl="1"
                        color="gray.500"
                        style={{
                            position: "absolute",
                            left: consts.axisTickLength,
                            top: y,
                            transform: "translate(0, -50%)",
                            textAlign: "left",
                            overflow: 'hidden',
                            textOverflow: 'clip',
                            whiteSpace: 'nowrap',
                        }}
                        fontSize="sm"
                    >{(time[1] / 1000)}</Text>
                })}

                <Text
                    color="gray.500"
                    position="absolute"
                    top={`${chartHeight / 2}px`}
                    right="0"
                    fontSize="sm"
                    transform={"rotate(90deg) translate(0, -100%) "}

                >Seconds</Text>
            </Box>


            <Box id="xAxis"
                position="absolute"
                top={`${chartHeight + consts.gutter}px`}
                width={`${chartWidth}px`}
                left={`${consts.decibelAxisWidth + consts.gutter}px`}
                height={`${consts.xAxisHeight}px`}
            >

                <svg height="100%" width="100%"
                    style={{
                        position: "absolute",
                        overflow: "visible"
                    }}>

                    <line y1={0} y2={0} x1={0} x2={chartWidth} stroke="currentColor" />

                    {ticks.map((tick, i) => {
                        const x = xScale(tick[0])
                        return <line
                            key={`tickline-${tick[0]}-${tick[1]}`}
                            y1={0}
                            y2={consts.axisTickLength}
                            x1={x}
                            x2={x}
                            stroke="currentColor"
                        />
                    })}

                </svg>

                {ticks.map((tick, i) => {
                    const x = xScale(tick[0])
                    return <Text
                        key={`tick-${tick[0]}-${tick[1]}`}
                        w={`${consts.xAxisTextWidth}px`}
                        color="gray.500"
                        style={{
                            position: "absolute",
                            top: consts.axisTickLength,
                            left: x,
                            textAlign: "left",
                            overflow: 'hidden',
                            textOverflow: 'clip',
                            whiteSpace: 'nowrap',
                        }}
                        fontSize="xs"
                    >{tick[1]} Hz</Text>
                })}

            </Box>


            <Box
                position="absolute"
                left={`${consts.decibelAxisWidth + consts.gutter}px`}
                top="0"
                w={`${chartWidth}px`}
                h={`${chartHeight}px`}
            >

                {components}

                <svg height="100%" width="100%"
                    style={{
                        position: "absolute",
                        overflow: "visible"
                    }}>

                    {paths}

                </svg>
            </Box>

        </Box>
    </Box>
}